/**
* Author: Korre D. Henry 
* File: TestimonialComponent.tsx
* Description: This file exports
* a TestimonialComponent component
* that describes a carousel of
* Card Components where
* each Card contians some 
* data related to testimonial data.
* Note that this component
* relies upon the "TestimonialDB.json"
* file which contains an array 
* of testimonial data.
*/

import { Carousel } from 'react-responsive-carousel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import "../../styles/TestimonialComponent.css";
import data from "../../Data/TestimonialDB.json";

//Inner Function Card Component for Testimonial Carousel Deck
const SimpleCard = (title:string, name:string, text:string) =>
    <Card className="simpleCard">
        <CardContent className="simpleCardContent" >
            <Typography id="testimonial-text" style={{color: "white"}} sx={{ mb: 2.7 }} variant="h4">
                {'"'+text+'"'} {/* Testiominal text */}
            </Typography>
            <Typography style={{color: "white"}} align="left" variant="h5">
                <strong>{name}</strong> {/* Name of person */}
            </Typography>
            <br />
            <Typography style={{color: "skyblue"}} align="left" variant="subtitle1">
                {title} {/* Title associated to person. */}
            </Typography>
        </CardContent>
    </Card>
;

//Get testmonial-like Array from the "TestimonialDB.json" file.
var testimonialList = data.testimonialList;

//Map all Testimonial data onto a single Card Component. (i.e SimpleCard)
const testimonialCards:any = testimonialList.map((data) => { return SimpleCard(data.title, data.name, 
    data.text.join(""))});

// Exports the TestimonialComponent component. This componet will be used as
// as SlideShow/Carousel for Tesimonials to be rendered component in 
// this App's production.
export function TestimonialComponent() {
    return (
        <div id="car2">
            <Carousel 
                showIndicators={false} 
                showStatus={false} 
                preventMovementUntilSwipeScrollTolerance={true} 
                swipeScrollTolerance={30}
            >
                    {/* Renders an array of Card Components 
                    (with the respective testimonial data*/}
                    {testimonialCards} 
            </Carousel>
        </div>
    );
}