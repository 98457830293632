import { Component } from 'react';
import photo1 from '../../images/ExploreInformationBlock/image-20210921-221712.png'
import '../../styles/ExploreInformationBlock.css'

interface ExploreInformationBlockProps {}

interface ExploreInformationBlockState {}

export class ExploreInformationBlock extends Component<ExploreInformationBlockProps, ExploreInformationBlockState> {

    render() {
        return (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <div className="div1" style={{float:'left', margin: 50, justifyContent:'left', alignItems:'left'}}>
                    <h1 className="h1">NEW TECHS 2023<span style={{color: "#26a1df"}}>.</span></h1>
                    <img className="img1" src= {photo1} alt="New Technologist Interns Working" style={{float:'left', margin: 50}}/>
                    <div className='eibText'>
                        <hr className="horizontal" />
                        <br/>
                        <br/>
                        <p className='eibText' style={{textAlign: 'left'}}>
                            Join our 7-week innovation academy. You’ll learn all about the product lifecycle and create a prototype solution for the modern customer. What’s the product life cycle? What’s prototyping? Don’t worry…we’ll teach you!
                            <br/>
                            <br/>
                            You’ll get real world experience in web development and gain hands-on training from senior staff at Microsoft. New Techs are provided with hardware/software and will receive a $8,000 stipend for their work.
                            <br/>
                            <br/>
                            <b>Interested in learning more? Then check out our criteria to apply and sign-up for our mailing list!</b>
                        </p>

                        <button className="buttonBlueEib" onClick={() => { 
                            const criteria = document.getElementById('criteriaToApply')
                            if (criteria) {
                                criteria.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                            }
                        }}>See Criteria to Apply</button>
                        <button className="buttonBlueEib" onClick={() => { 
                            const mailing = document.getElementById('mailingList')
                            if (mailing) {
                                mailing.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                            }
                        }}>Join our Mailing List</button>
                    </div>
                </div>
            </div>
            );
        }
    }
