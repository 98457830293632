import React, { Component } from 'react';
import photo1 from '../../images/AboutYou/AboutYou.png'
import '../../styles/AboutYou.css'

interface AboutYouProps {}

interface AboutYouState {}

export class AboutYou extends Component<AboutYouProps, AboutYouState> {

    render() {
        return (
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <div style={{float:'left', margin: 50, justifyContent:'left', alignItems:'left'}}>
                        <img className="img2" src= {photo1} alt="New Technologist Interns at Microsoft"/>
                        <div className='abText'>
                            <h1 className="h1">WHO YOU ARE<span style={{color: "#26a1df"}}>.</span></h1>
                            <hr style={{marginLeft: 62}}className="horizontal" />
                            <br/>
                            <div className='abSection'>
                            <ul style={{textAlign: 'left', fontSize: 20}}>
                                <li>Natural Leader​</li>
                                <li>Fast Learner​</li>
                                <li>Creative Problem Solver</li>
                                <li>Inclusive Teammate and Collaborator</li>
                                <li>Uncommonly Curious​</li>
                                <li>Passionate about Tech, Driven and Resilient​</li>
                            </ul>
                            <br/>
                            <p style={{textAlign: 'left', fontSize:20, marginLeft:10+'px'}}><b>​If any of this sounds like you, join our team this summer!</b></p>
                            <button style={{float: 'left', paddingLeft:50, paddingRight:50, marginBottom: 50}} className="buttonBlue" onClick={() => { 
                                const mailing = document.getElementById('mailingList')
                                if (mailing) {
                                    mailing.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                                }
                            }}>Join Us</button>
                            </div>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
            );
        }
    }
    