import { Component } from 'react';
import picture1 from '../../images/hero/New-Techs-14-2-scaled-1.jpg'
import picture2 from '../../images/hero/slider-img-main-1b.jpg'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../styles/hero.css";

interface HeroProps {}

interface HeroState {}

export class Hero extends Component<HeroProps, HeroState> {

    render() {
        return (
            <div id="car1">
                <Carousel showIndicators={false} showStatus={false} infiniteLoop={true} autoPlay={true} showThumbs={false}>
                    <div className = "carousel active img-fluid" data-interval="1000">
                        <img src= {picture1} alt=""/>
                    </div>
                    <div className = "carousel active img-fluid" data-interval="1000">
                        <img src= {picture2} alt=""/>
                    </div>
                </Carousel>
            </div>
        );
    }
}
