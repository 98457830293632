import { Component } from 'react';
import '../../styles/Footer.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { SocialIcon } from 'react-social-icons';
import ScrollButton from '../../components/BackToTop/ScrollButton';

interface FooterProps {}

interface FooterState {}

export class Footer extends Component<FooterProps, FooterState> {

    render() {
        return (
                <div className ="footer-container-div">
                    <div className="spacer" />
                    <Container style={{width: 75 + '%'}}>
                        <Row className="row-content-container" xs={1} sm={1} md={2}>
                            <Col>
                                <Row>
                                    <div style={{float:'left'}}>     
                                        <h1 className="colH1">NEW TECHS<span style={{color: "#26a1df"}}>.</span></h1>
                                    </div>
                                </Row>
                                <Row>
                                    <p className="p">
                                        Join Us Summer 2023.
                                        <br/>
                                        Build Your Passion for Tech!
                                    </p>
                                </Row>
                                <Row>
                                    <div className="divider"/>
                                    <SocialIcon url="https://www.instagram.com/newtechnologists/" target="_blank"/>
                                    <div className="divider"/>
                                    <SocialIcon url="https://www.linkedin.com/company/newtechnologists/" target="_blank"/>
                                </Row>
                            </Col>
                            <Col>
                                <div className='midCol'>
                                    <h3 className="h3font">Cyborg Mobile</h3>
                                    <p className="pItalic">
                                        Everybody is welcome. We hire the best people based on an evaluation of their abilities and effectiveness.
                                    </p>
                                    <p className="pItalic">
                                        We do not discriminate against applicants on the basis of any personal characteristic or classification protected by federal, state or local law.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="spacer1" />
                    <div>
                        <p className="pCM">
                            Copyright 2023. <a target="_blank" rel="noreferrer" className="cmLink" href="https://cyborgmobile.com/" >Cyborg Mobile</a>
                        </p>
                    </div>
                    <ScrollButton/>
                </div>
            );
        }
    }