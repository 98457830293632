import { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { PathNames } from './PathNames';

import 'bootstrap/dist/css/bootstrap.min.css';

export default class App extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact 
          path={PathNames.Home}
          component={HomePage}
        />
      </Switch>
    )
  }
}