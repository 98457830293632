/**
* Author: Korre D. Henry 
* File: FAQComponent.tsx
* Description: This file exports
a FAQComponent component 
* which renders a FAQ dropdown
* section following the model
* of the FAQModel (Object Model).
*/

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "../../styles/FAQComponent.css";
import data from "../../Data/FAQModelDB.json";

//Get FAQ-like Array from the "FAQModelDB.json" file.
var FAQList = data.FAQList;

//Inner Functional Component for FAQModel objects
const SimpleFAQ = (question:string, answer:string, link?:string, mailTo?: string) => {
    if (link !== undefined) {
        return(
            <div className="simpleFAQ">
                <strong >
                    <p style={{  marginBottom: "10" }}> {question} </p>
                </strong>
    
                <ul> <li style={{ color: "#777" }}> {answer} <a href={link}>{link}</a> </li> </ul>
            </div>
        )
    } else if (mailTo !== undefined) {
        return(
            <div className="simpleFAQ">
                <strong >
                    <p style={{  marginBottom: "10" }}> {question} </p>
                </strong>
    
                <ul> <li style={{ color: "#777" }}> {answer} <a href={"mailto:"+mailTo}>{mailTo}</a> </li> </ul>
            </div>
        )
    } else {
        return(
            <div className="simpleFAQ">
                <strong >
                    <p style={{  marginBottom: "10" }}> {question} </p>
                </strong>
    
                <ul> <li style={{ color: "#777" }}> {answer} </li> </ul>
            </div>
        )
    }
};

//Map all FAQ data onto a single FAQ sub-component. (i.e SimpleFAQ)
const FAQObjects:any = FAQList.map((data) => { return SimpleFAQ(
    data.question,
    data.answer,
    data.link,
    data.mailTo
)});


// Exports the FAQComponent component. This componet will be used as
// as dropdown section for (Frequently Asked Questions) to be 
//rendered component in this App's production.
export function FAQComponent(){
    const [open, setOpen] = React.useState(false);
    const [isDesktop, setDesktop] = React.useState(window.innerWidth > 650);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 950);
      };
    
      React.useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
      });

    //Down arrow icon
    const downArrowIcon = <FontAwesomeIcon  className={"iconArrow"} style={{
        display: open ? "none" : "inline"}} icon={faAngleDown}  
    onClick={() => {setOpen(!open);}}  />;

    //Up arrow icon
    const upArrowIcon = <FontAwesomeIcon className={"iconArrow"} style={{
        display: !open ? "none" : "inline"}} icon={faAngleUp}  
    onClick={() => {setOpen(!open);}} />;



  return (
    <div className="faqHeaderContainer">
        {isDesktop && 
            <h1 className="faqHeader" style={{display:"inline"}}>Frequently Asked Questions</h1>
        }
        {!isDesktop && 
            <h1 className="faqHeader" style={{display:"inline"}}>FAQ</h1>
        }
        {upArrowIcon} 
        {downArrowIcon}
        <div className={open ? "faqContainer-show" : "faqContainer-hide"}>
        {/* Renders an array of FAQ sub-components 
        (as described above) with the respective 
        FAQModel data*/}
        {FAQObjects} 
        </div>
    </div>
  );
}