import React, { Component } from 'react';
import './HomePage.css';
import { Hero } from '../components/Hero/Hero'
import { ExploreInformationBlock } from '../components/ExploreInformationBlock/ExploreInformationBlock';
import { VideoComponent } from "../components/VideoComponent/VideoComponent";
import { HeroFooter } from '../components/HeroFooter/HeroFooter';
import { AboutYou } from "../components/AboutYou/AboutYou";
import { TestimonialComponent } from '../components/TestimonialComponent/TestimonialComponent';
import { ApplicationCriteria } from "../components/ApplicationCriteria/ApplicationCriteria";
import { MailingList } from "../components/MailingList/MailingList";
import { FAQComponent } from "../components/FAQComponent/FAQComponent";

import { Footer } from "../components/Footer/Footer";

interface HomePageProps {}

interface HomePageState {}

export class HomePage extends Component<HomePageProps, HomePageState> {

    render() {
        return (
          <div className="App">
              <Hero/>
              <HeroFooter/>
              <ExploreInformationBlock/>
              <VideoComponent/>
              <TestimonialComponent/>
              <AboutYou />
              <ApplicationCriteria />
              <MailingList />
              <FAQComponent />
              <Footer/>
          </div>
        )
    }
}
