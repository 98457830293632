import { Component } from 'react';
import '../../styles/ApplicationCriteria.css';

interface ApplicationCriteriaProps {
}

interface ApplicationCriteriaState {
    applicationStatus: ApplicationStatus;
}


export enum ApplicationStatus { 
    preApp, inApp, postApp
}

export class ApplicationCriteria extends Component<ApplicationCriteriaProps, ApplicationCriteriaState> {
    constructor(props: ApplicationCriteriaProps | Readonly<ApplicationCriteriaProps>){
        super(props);
        this.state = {applicationStatus: ApplicationStatus.postApp}
        /*  let date1 = new Date();
        var now_utc =  Date.UTC(date1.getUTCFullYear(), date1.getUTCMonth(), date1.getUTCDate(), date1.getUTCHours(), date1.getUTCMinutes(), date1.getUTCSeconds());
        let inAppDate = new Date("2022-01-10T08:00:00.000+00:00");
        var inAppUTCDate = Date.UTC(inAppDate.getUTCFullYear(), inAppDate.getUTCMonth(), inAppDate.getUTCDate(), inAppDate.getUTCHours(), inAppDate.getUTCMinutes(), inAppDate.getUTCSeconds());
        let postAppDate = new Date("2022-02-08T01:00:00.000+00:00");
        var postAppUTCDate = Date.UTC(postAppDate.getUTCFullYear(), postAppDate.getUTCMonth(), postAppDate.getUTCDate(), postAppDate.getUTCHours(), postAppDate.getUTCMinutes(), postAppDate.getUTCSeconds());
        if(now_utc > inAppUTCDate) {
            this.state = {applicationStatus: ApplicationStatus.inApp}
        } 
        if (now_utc > postAppUTCDate) {
            this.state = {applicationStatus: ApplicationStatus.postApp}
        } */
    }
    render() {
        return (
            <div className ="div2" id='criteriaToApply'>
                <br/>
                <div style={{margin: 50, marginBottom: 0}}>
                    <h1 className="h1">CRITERIA TO APPLY<span style={{color: "#26a1df"}}>.</span></h1>
                    <hr style={{marginLeft: 52}}className="horizontal" />
                    <br/>
                    <div className='acSection'>
                    <p style={{textAlign: 'left', fontSize: 20}} >All candidates who meet the criteria below may apply:</p>
                    {this.renderCriteriaList()}
                    <br/>
                    {this.renderAppStatusText()}
                    <p style={{textAlign: 'left', fontSize:15}}><i>We especially encourage applications from individuals currently underrepresented in engineering (women, Native-Americans, African-Americans, Latinx and Pacific Islanders) and non-traditional candidates (2-year college students, non-computer science majors, etc.).</i></p>
                    </div>
                    <br/>
                </div>
            </div>
        )
    }

    renderCriteriaList = () => {
        return (
            <ul style={{textAlign: 'left', fontSize: 20}}>
                <li>Must be a current college freshman (class of 2026) or sophomore (class of 2025)</li>
                <li>Completed college level Intro to Computer Science (or equivalent) by start of program</li>
                <li>Completed college level Pre-Calculus (or equivalent) by start of program</li>
                <li>Available to work in-person in Redmond, WA for the entire program; June—August 2023 (exact dates to be confirmed soon)</li>
                <li>Must obtain authorization to work in the U.S. and a social security number to accept the job offer</li>
                <li>Must be at least 18 years old by June 2023</li>
            </ul>
        )
    }

    renderAppStatusText = () => {
        if (this.state.applicationStatus === ApplicationStatus.preApp) {
            return (
                <p style={{textAlign: 'left', fontSize:20}}><b>Our application will open on January 9th, 2023. Please join our mailing list below to get the latest updates about The New Technologists program.</b></p>
            )
        } else if (this.state.applicationStatus === ApplicationStatus.inApp) {
            return (
                <div>
                    <p style={{textAlign: 'left', fontSize:20}}><b>Deadline to Apply: <br/><span style={{color: "#26a1df", fontSize: 30}}>February 6th, 2023 at 5:00pm PST</span></b></p>
                    <button style={{float: 'left', paddingLeft:50, paddingRight:50, marginBottom: 50}} className="buttonBlue"><a style={{color: 'white', textDecoration: 'none'}}href="https://cyborgmobile.breezy.hr/p/08c8a5016949" target="_blank" rel="noreferrer">Apply To Position</a></button>
                </div>
            )
        } else {
            return (
                <p style={{textAlign: 'left', fontSize:20}}><b>​<b>Our 2023 application is now closed. </b>Please join our mailing list below to get the latest updates about the New Technologists program.</b></p>
            )
        }
    }
}
