import React, { Component } from 'react';
import '../../styles/MailingList.css'

interface MailingListProps {}

interface MailingListState {}

export class MailingList extends Component<MailingListProps, MailingListState> {

    render() {
        return (
                <div className ="div3" id='mailingList'>
                    <form action="https://cyborgmobile.us7.list-manage.com/subscribe/post?u=9e46645a263f0afa8ce466f92&amp;id=5ef0580bc8" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                        <div id="mc_embed_signup_scroll">
                        <h1 style={{float: 'left', marginTop: 50, width: 100 + '%'}}className="text"> Join Our Mailing List!</h1>
                        <div style={{width: 100 + '%'}}>
                            <div className="mc-field-group">
                                <input type="email" name="EMAIL" className="required" id="mce-EMAIL" placeholder="EMAIL" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
                            </div>
                            <div className="mc-field-group">
                                <input type="text" name="FNAME" className="required" id="mce-FNAME" placeholder="FIRST NAME" pattern="[A-Za-z]{2,100}" required />
                            </div>
                            <div className="mc-field-group">
                                <input type="text" name="LNAME" className="required" id="mce-LNAME" placeholder="LAST NAME" pattern="[A-Za-z]{2,100}" required />
                            </div>
                        </div>
                        <div id="mce-responses" className="clear">
                            <div className="response" id="mce-error-response" style={{display: "none"}}></div>
                            <div className="response" id="mce-success-response" style={{display: "none"}}></div>
                        </div>    
                        {/*<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->*/}
                        <div style={{position: "absolute", left: -5000}} aria-hidden="true"><input type="text" name="b_9e46645a263f0afa8ce466f92_5ef0580bc8" value=""></input></div>
                        <br/>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}className="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"></input></div>
                        <br/>
                        </div>
                    </form>
                </div>
            );
        }
    }