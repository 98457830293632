/**
* Author: Korre D. Henry 
* File: VideoComponent.tsx
* Description: This file exports
a VideoComponent component 
* which renders any given video 
* (provided by some local or
* web source URL) that is
* set at the component level.
*/

import ReactPlayer from "react-player";
import "../../styles/VideoComponent.css";

// Exports the VideoComponent component. This componet will be used as
// as a video rendering component in this App's production.
export function VideoComponent() {
  return (
    //The .player-wrapper & .react-player classes
    //respectively allow the ReactPlayer component
    //to render the video using 100% of the entire
    //screen. The default to this component
    //is 640px in width=100%.
    <div className="player-wrapper">
        <ReactPlayer
          url="https://vimeo.com/285193628"
          className="react-player"
          playing
          width="100%"
          height="100%"
          controls={true}
        />
    </div>
  );
};
