/**
* Author: Korre D. Henry 
* File: HeroFooter.tsx
* Description: This file exports
* a HeroFooter component styled
* using the css stylesheet stored
* in the HeroFooter.css file.
*/
import {Stack, StackItem} from '@fluentui/react';
import {IStackStyles, IStackTokens} from 'office-ui-fabric-react/lib/Stack';
import '../../styles/HeroFooter.css';
import canvas from '../../images/HeroFooter/canvas-logo.png';
import lightBulb from '../../images/HeroFooter/light-bulb.png';
import code from '../../images/HeroFooter/code-logo.png';

// Stack Styles definition
const stackStyles: IStackStyles = {
  root: {
    backgroundColor: "black",
    overflow: "visible"
  },
};

// Stack Tokens definition
const containerStackTokens: IStackTokens = { 
    childrenGap: 60,
    padding: 40,
};

// Stack Style Definitions
const itemStyle: React.CSSProperties = {
    color: "white",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

// Exports the HeroFooter component. This componet will be used as
// as Footer in this App's production.
export function HeroFooter () {
    return (
            // This an Over-Arching Stack (horizontal), 
            // where the children are Stack Items (growing Stack Items). 
            // Each Stack Item contains a single child, which is a Veritcal 
            // Stack, that holds the main content as either a sperate Stack Item or
            // a general HTML element.
            <div style={{backgroundColor: 'black'}}>
                <Stack wrap horizontal id="stack" styles={stackStyles} tokens={containerStackTokens} className="bodyFooter">
                    <StackItem grow style={itemStyle}>
                        <Stack>
                            <StackItem>
                                <img className="footer-image" src={lightBulb} data-testid="lightBulb" alt="logo"/>
                            </StackItem>
                            <h2>Innovate</h2>
                            <p>Bring fresh ideas</p>
                        </Stack>
                    </StackItem>
                    <StackItem grow style={itemStyle}>
                        <Stack>
                            <StackItem>
                                <img className="footer-image" src={canvas} data-testid="canvas"  alt="logo"/>
                            </StackItem>
                            <h2>Design</h2>
                            <p>Prototype a new product concept</p>
                        </Stack>
                    </StackItem>
                    <StackItem grow style={itemStyle}>
                        <Stack>
                            <StackItem>
                                <img className="footer-image" src={code} data-testid="code" alt="logo"/>
                            </StackItem>
                            <h2>Code</h2>
                            <p>Gain technical experience</p>
                        </Stack>
                    </StackItem>
                </Stack>
            </div>
        );
}

